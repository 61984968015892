import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carousels = document.querySelectorAll(
    '.testimonials-carousel__carousel'
  );

  if (0 < carousels.length) {
    carousels.forEach((carousel) => {
      const slides = carousel.querySelectorAll('.swiper-slide');
      const args = {
        slidesPerView: 1,
        spaceBetween: 20,
        centerInsufficientSlides: true,

        navigation: {
          prevEl: carousel.querySelector('.swiper-button-prev'),
          nextEl: carousel.querySelector('.swiper-button-next'),
        },

        breakpoints: {
          680: {
            slidesPerView: 2,
          },
          1060: {
            slidesPerView: 3,
          },
        },
      };

      if (3 < slides.length) {
        args.loop = true;
      } else {
        args.watchOverflow = true;
      }
      new Swiper(carousel, args);
    });
  }
});
